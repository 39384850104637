import { Card, CardBody, Row, Col, Input, Button, Label, FormGroup, Alert, Nav, NavItem, NavLink } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { fetchCustomers, fetchBuilding, fetchBuildingInstallers, fetchBuildingControllers, fetchBuildingContact } from '../../../data/dataFetch';
import { updateBuilding, createBuilding, deleteBuilding, createInstaller, deleteInstaller, createContact, updateContact } from '../../../data/dataUpdate';
import confirm from 'reactstrap-confirm';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableSearchable from "../../common/TableSearchable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import Select from 'react-select';
import { isIterableArray } from "../../../helpers/utils";
import moment from 'moment';
import { toast } from 'react-toastify';

const Building = () => {

    const { id } = useParams();
    const [isNew, setIsNew] = useState(false);
    const [isEditing, setIsEditing] = useState(true);
    const [initialBuilding, setInitialBuilding] = useState({ building_name: '', street: '', zip: '', city: '', state: '', organization_number: '' });
    const [building, setBuilding] = useState(initialBuilding);
    const [installers, setInstallers] = useState([]);
    const history = useHistory();
    const [installerShow, setInstallerShow] = useState(false);
    const [contactShow, setContactShow] = useState(false);
    const [contact, setContact] = useState({ first_name: '', last_name: '', phone: '' });
    const [customers, setCustomers] = useState([]);
    const [installer, setInstaller] = useState({ email: '', valid_from: new Date(), valid_to: new Date(), building_id: parseInt(id) });
    const [activeTab, setActiveTab] = useState("installer");
    const [controllers, setControllers] = useState([]);

    useEffect(() => {
        const loadBuilding = async () => {
            var data = await fetchBuilding(id);
            var copy = JSON.parse(JSON.stringify(data));
            setIsNew(false);
            setInitialBuilding(copy);
            setBuilding(copy);
            loadInstallers(copy.building_id);
            loadControllers(copy.building_id);
            loadContact(copy.contactid)
        }
        if (id === 'new') {
            setInitialBuilding({ building_name: '', street: '', zip: '', city: '', state: '' });
            setBuilding(initialBuilding);
            setIsNew(true);
        }
        else {
            loadBuilding();
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
    }, [installers])

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomers(await fetchCustomers());
        }
        loadCustomers();
    }, [])


    const loadInstallers = async (buildingId) => {
        var data = (await fetchBuildingInstallers(buildingId)) ?? [];
        setInstallers(data);
    }

    const loadControllers = async (buildingId) => {
        var data = (await fetchBuildingControllers(buildingId)) ?? [];
        setControllers(data);
    }
    const loadContact = async (contactid) => {
        if (contactid) {
            var data = await fetchBuildingContact(contactid);
            if (data &&
                data.international_dialing_code &&
                data.phone_number)
                data.phone = data.international_dialing_code + data.phone_number;
            setContact(data);
        }
    }

    const deleteCurrentInstaller = async (installerId) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            await deleteInstaller(installerId);
            loadInstallers(id);
        }
    };

    const saveInstaller = (currentInstaller) => {
        createInstaller(currentInstaller);
        loadInstallers(id);
    }

    const insertOrUpdateContact = async (currentContact) => {
        console.log('currentContact', currentContact);
        console.log('building', building);


        if (building.contactid) {
            console.log("Update");
            currentContact.phone_number = currentContact.phone;
            currentContact.contact_id = building.contactid;
            const result = await updateContact(currentContact);
            if (result) {
                loadContact(building.contactid);
                toast.success('kontakt er oppdatert');
            }
        } else {
            console.log("Create");
            currentContact.phone_number = currentContact.phone;
            const result = await createContact(id, currentContact);
            if (result) {
                loadContact(building.contactid);
                toast.success('kontakt er lagt til');
            }
        }
    }



    const installersColumns = [

        {
            accessor: 'email',
            Header: 'Epost',
        },
        {
            accessor: 'valid_from',
            Header: 'Gyldig fra',
        },
        {
            accessor: 'valid_to',
            Header: 'Gyldig til',
        },
        {
            accessor: 'pairing_code',
            Header: 'Paringskode',
        },
        {
            Headers: '',
            accessor: 'installer_id',
            Cell: props => {
                const row = props.row.original;
                return <div style={{ textAlign: 'center' }} >
                    <Button
                        onClick={() => { deleteCurrentInstaller(row.installer_id) }}
                        color="outline-primary" size="sm" >
                        <FontAwesomeIcon icon="trash" />
                    </Button>
                </div>;
            },
            maxWidth: 50
        }

    ]

    const controllersColumns = [

        {
            accessor: 'controller_serial',
            Header: 'Controller Serial',
        },
        {
            accessor: 'mac',
            Header: 'Mac',
        },
        {
            accessor: 'ip_address',
            Header: 'Ip Address',
        },

    ]

    const isBuildingUnchanged = () => {
        return (JSON.stringify(initialBuilding) === JSON.stringify(building));
    }

    const insertOrUpdateBuilding = async () => {
        async function insertBuilding() {
            const result = await createBuilding(building);
            if (result){
                setIsEditing(false);
                setInitialBuilding(building);
                toast.success('bygning er lagt til');
            }
            else
                toast.error('Det har oppstått en feil: ');
        }

        async function modifyBuilding() {
            const res = await updateBuilding(building);
            if (res.status === 200) {
                setIsEditing(false);
                setInitialBuilding(building);
                toast.success('bygning er oppdatert');
            }
            else
                toast.error('Det har oppstått en feil ');
        }
        if (isNew)
            await insertBuilding();
        else {

            await modifyBuilding();
        }

    }

    const deleteCurrentBuilding = async (id) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            const res = await deleteBuilding(id);
            if (res && res.status === 200)
                history.push(`/`);
            else
                toast.error('Det har oppstått en feil ');
        }

    }


    return (
        <>
            <Modal show={installerShow} size='lg'>

                <ModalHeader>NY MONTØR</ModalHeader>
                <ModalBody>
                    <div className="mb-5">

                        <Label>Epost</Label>

                        <Input
                            value={installer.email}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setInstaller({ ...installer, email: target.value })
                            }}
                        />

                        <Label>Gyldig fra</Label>
                        <Input
                            type="datetime-local"
                            value={moment(installer.valid_from).format('YYYY-MM-DDTHH:mm:ss')}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setInstaller({ ...installer, valid_from: new Date(target.value) })

                            }}
                        />

                        <Label>Gyldig til</Label>
                        <Input
                            type="datetime-local"
                            value={moment(installer.valid_to).format('YYYY-MM-DDTHH:mm:ss')}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setInstaller({ ...installer, valid_to: new Date(target.value) })
                            }}
                        />

                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        saveInstaller(installer);
                        setInstallerShow(false);
                        loadInstallers(id);
                    }}
                    >
                        Bruk
                    </Button>
                    <Button color="warning" onClick={() => setInstallerShow(false)}>
                        Avbryt
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal show={contactShow} size='lg'>

                <ModalHeader>NY KONTAKT</ModalHeader>
                <ModalBody>
                    <div className="mb-5">

                        <Label>Fornavn</Label>
                        <Input
                            value={contact.first_name}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setContact({ ...contact, first_name: target.value })
                            }}
                        />

                        <Label>Etternavn</Label>
                        <Input
                            value={contact.last_name}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setContact({ ...contact, last_name: target.value })
                            }}
                        />

                        <Label>Telefone</Label>
                        <Input
                            value={contact.phone}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setContact({ ...contact, phone: target.value })
                            }}
                        />
                        <Label>Epost</Label>
                        <Input
                            value={contact.email}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setContact({ ...contact, email: target.value })
                            }}
                        />
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        insertOrUpdateContact(contact);
                        setContactShow(false);
                    }}
                    >
                        Bruk
                    </Button>
                    <Button color="warning" onClick={() => setContactShow(false)}>
                        Avbryt
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="mb-5">
                <Card>
                    <Row className="align-items-center p-3" >
                        <Col>
                            <h5>{isNew ? "NY" : ""} BYGNING {!isNew ? building.building_name : ""}</h5>
                        </Col>
                        <Col xs="auto" className="text-right">
                            <Link to={`/`} className="mr-2">
                                <Button color="primary" size="sm"> &lt; Bygninger</Button>
                            </Link>
                            {!isNew &&
                                < Button
                                    onClick={() => { deleteCurrentBuilding(building.building_id); }}
                                    color="danger" size="sm">Slett
                                </Button>}
                            {" "}
                            <Button
                                onClick={() => {
                                    if (isEditing) {
                                        setBuilding(JSON.parse(JSON.stringify(initialBuilding)));
                                        if (isNew) {
                                            history.push(`/`);
                                        }
                                    }
                                    setIsEditing(!isEditing);
                                }}
                                color="warning" size="sm">Avbryt
                            </Button>
                        </Col>
                    </Row>
                    <CardBody>
                        <Row>
                            <Col>

                                <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                                    <Label>Bygning</Label>
                                    <Input
                                        value={building.building_name}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, building_name: target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mr-2 mb-2">
                                    <Label>Gate</Label>
                                    <Input
                                        value={building.street}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, street: target.value })
                                        }}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <FormGroup className="mr-2 mb-2">
                                    <Label>Poststed</Label>
                                    <Input
                                        value={building.city}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, city: target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>

                                <FormGroup className="mr-2 mb-2">
                                    <Label>Postnummer</Label>
                                    <Input
                                        value={building.zip}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, zip: target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <FormGroup className="mr-2 mb-2">
                                    <Label>Fylke</Label>
                                    <Input
                                        value={building.state}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, state: target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>


                                <FormGroup className="mr-2 mb-2">
                                    <Label>Eier</Label>
                                    <Select
                                        options={
                                            isIterableArray(customers) && Object.values(customers).map((customer) => ({ value: customer.customer_id, label: customer.customer_name }))
                                        }
                                        value={({ value: building.customer_id, label: building.customer_name })}
                                        onChange={({ value }) => {
                                            var selectedCustomer = customers.find(v => v.customer_id === Number(value));
                                            setBuilding({ ...building, customer_id: value, customer_name: selectedCustomer.customer_name });

                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <FormGroup className="mr-2 mb-2">
                                    <Label>Organisasjonsnummer</Label>
                                    <Input
                                        value={building.organization_number}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setBuilding({ ...building, organization_number: target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col></Col>
                        </Row>

                    </CardBody>
                    <Row className="align-items-center p-3" >
                        {!isNew &&
                            <Col xs="auto" className="text-right">
                                {" "}
                                <Button
                                    onClick={() => setInstallerShow(true)}
                                    color="outline-primary" size="sm">
                                    <FontAwesomeIcon icon="plus" />
                                    Legg til mentør til bygning
                                </Button>
                                {" "}
                                <Button
                                    onClick={() => setContactShow(true)}
                                    color="outline-primary" size="sm">
                                    {!building.contactid && <FontAwesomeIcon icon="plus" />}
                                    {building.contactid ? 'Oppdater kontakt' : 'Legg til kontak'}
                                </Button>

                            </Col>

                        }
                    </Row>
                    <>
                        {!isNew &&
                            <Card>
                                <Nav tabs>
                                    <NavItem className='cursor-pointer'>
                                        <NavLink active={activeTab === 'installer'} onClick={() => setActiveTab('installer')}>Montører</NavLink>
                                    </NavItem>
                                    <NavItem className='cursor-pointer'>
                                        <NavLink active={activeTab === 'controller'} onClick={() => setActiveTab('controller')}>Kontrollere</NavLink>
                                    </NavItem>
                                    <NavItem className='cursor-pointer'>
                                        <NavLink active={activeTab === 'contact'} onClick={() => setActiveTab('contact')}>Kontakt</NavLink>
                                    </NavItem>

                                </Nav>
                                {activeTab === 'installer' &&
                                    <div className="ml-3 mr-3 mb-2">
                                        <TableSearchable
                                            columns={installersColumns}
                                            data={installers
                                                && installers.map(value => {
                                                    if (value) {
                                                        const full_name = (value?.first_name ?? '') + ' ' + (value?.last_name ?? '');
                                                        return {
                                                            ...value,
                                                            full_name: full_name
                                                        }
                                                    }
                                                    return null;
                                                })
                                            }
                                            size={10}

                                        />
                                    </div>
                                }
                                {activeTab === 'controller' &&
                                    <div className="ml-3 mr-3 mb-2">
                                        <TableSearchable
                                            columns={controllersColumns}
                                            data={controllers}
                                            size={10}
                                        />
                                    </div>
                                }
                                {activeTab === 'contact' &&
                                    <div className="ml-3 mr-3 mb-2">
                                        <Row>
                                            <Col>
                                                <Label>Fornavn</Label>
                                                <Input
                                                    value={contact.first_name}
                                                    onFocus={(event) => { event.target.select() }}
                                                    onChange={({ target }) => {
                                                        setContact({ ...contact, first_name: target.value })
                                                    }}
                                                    readOnly={true}
                                                />
                                            </Col><Col>
                                                <Label>Etternavn</Label>
                                                <Input
                                                    value={contact.last_name}
                                                    onFocus={(event) => { event.target.select() }}
                                                    onChange={({ target }) => {
                                                        setContact({ ...contact, last_name: target.value })
                                                    }}
                                                    readOnly={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>Telefone</Label>
                                                <Input
                                                    value={contact.phone}
                                                    onFocus={(event) => { event.target.select() }}
                                                    onChange={({ target }) => {
                                                        setContact({ ...contact, phone: target.value })
                                                    }}
                                                    readOnly={true}
                                                />
                                            </Col><Col>
                                                <Label>Epost</Label>
                                                <Input
                                                    value={contact.email}
                                                    onFocus={(event) => { event.target.select() }}
                                                    onChange={({ target }) => {
                                                        setContact({ ...contact, email: target.value })
                                                    }}
                                                    readOnly={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                }
                            </Card>

                        }
                    </>

                </Card>
                {
                    isBuildingUnchanged() ? null : <div className="fixed-top">
                        <Alert color="danger">
                            <Row>
                                <Col sm="auto">
                                    <Button onClick={() => insertOrUpdateBuilding()} color="primary" >
                                        Bruk
                                    </Button>
                                    {" "}
                                    {!isNew &&
                                        <Button color="danger" >
                                            Tilbakstil
                                        </Button>
                                    }
                                </Col>
                                <Col className="mt-2" sm="auto">
                                    <span >
                                        Vil du lagre endringene?
                                    </span>
                                </Col>
                            </Row>
                        </Alert>
                    </div>
                }

            </div >
        </>

    );

};

export default Building;