import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebook,
    faFacebookF,
    faFacebookSquare,
    faGooglePlusG,
    faGulp,
    faHotjar,
    faLinkedinIn,
    faMediumM,
    faNodeJs,
    faSass,
    faTrello,
    faTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
    faCircle as farCircle,
    faClock as farClock,
    faComment as farComment,
    faCopy as farCopy,
    faClone as farClone,
    faClipboard as farClipboard,
    faDizzy as farDizzy,
    faFile as farFile,
    faFileArchive as farFileArchive,
    faFileAudio as farFileAudio,
    faFileCode as farFileCode,
    faFileImage as farFileImage,
    faFilePdf as farFilePdf,
    faFileVideo as farFileVideo,
    faHeart as farHeart,
    faImage as farImage,
    faLaughBeam as farLaughBeam,
    faLightbulb as farLightbulb,
    faObjectUngroup as farObjectUngroup,
    faPaperPlane as farPaperPlane,
    faQuestionCircle as farQuestionCircle,
    faSmileBeam as farSmileBeam,
    faStar as farStar,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAlignLeft,
    faAlignRight,
    faAngleRight,
    faArchive,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsAltV,
    faArrowUp,
    faAt,
    faBan,
    faBell,
    faBook,
    faCalendarAlt,
    faCalendarCheck,
    faCamera,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCartPlus,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleNotch,
    faClock,
    faCodeBranch,
    faCog,
    faComments,
    faCopy,
    faClone,
    faClipboard,
    faCrown,
    faDollarSign,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpen,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faFileAlt,
    faFileArchive,
    faFilePdf,
    faFilter,
    faFolderPlus,
    faGift,
    faGlobeAmericas,
    faHeart,
    faHome,
    faIcons,
    faImage,
    faInfo,
    faLink,
    faList,
    faListAlt,
    faListUl,
    faLocationArrow,
    faLock,
    faMinus,
    faMapMarkerAlt,
    faPalette,
    faPaperclip,
    faPencilAlt,
    faPercentage,
    faPhone,
    faPlay,
    faPlug,
    faPlus,
    faPlusSquare,
    faPoll,
    faPrint,
    faPuzzlePiece,
    faQuestionCircle,
    faRedo,
    faReply,
    faSearch,
    faShare,
    faShareAlt,
    faShoppingCart,
    faSignInAlt,
    faSlidersH,
    faSmileBeam,
    faSort,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortAmountDown,
    faSortAmountUp,
    faSortNumericDown,
    faSortNumericUp,
    faStar,
    faStarHalfAlt,
    faStream,
    faTable,
    faTag,
    faTh,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTools,
    faTrash,
    faTrashAlt,
    faUser,
    faUserCircle,
    faUserFriends,
    faUserPlus,
    faUsers,
    faVideo,
    faCross,
    faCloud,
    faCalculator,
    faLongArrowAltLeft,
    faSimCard
} from '@fortawesome/free-solid-svg-icons';

library.add(
    // Solid
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faCopy,
    faClone,
    faClipboard,
    faSearch,
    faHome,
    faBell,
    faPlay,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faArrowUp,
    faArrowDown,
    faExchangeAlt,
    faAngleRight,
    faEllipsisH,
    faCrown,
    faListAlt,
    faCheck,
    faBan,
    faStream,
    faTable,
    faArrowsAltV,
    faSort,
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
    faSortAmountDown,
    faSortAmountUp,
    faCheckCircle,
    faUserCircle,
    faQuestionCircle,
    faLock,
    faCircle,
    faHeart,
    faShareAlt,
    faCaretRight,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faPrint,
    faDollarSign,
    faLink,
    faPlus,
    faMinus,
    faUser,
    faPencilAlt,
    faCamera,
    faTimesCircle,
    faSignInAlt,
    faBook,
    faPuzzlePiece,
    faTrash,
    faAlignLeft,
    faAlignRight,
    faPlug,
    faTimes,
    faFilter,
    faExternalLinkAlt,
    faCartPlus,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faArchive,
    faEnvelope,
    faClock,
    faStar,
    faReply,
    faLocationArrow,
    faCog,
    faPaperclip,
    faImage,
    faEllipsisV,
    faEnvelopeOpen,
    faRedo,
    faSlidersH,
    faTrashAlt,
    faFileArchive,
    faFilePdf,
    faFileAlt,
    faEye,
    faCaretUp,
    faCodeBranch,
    faExclamationTriangle,
    faListUl,
    faTh,
    faGift,
    faUsers,
    faGlobeAmericas,
    faPlusSquare,
    faCaretDown,
    faUserPlus,
    faCircleNotch,
    faExclamation,
    faThumbsUp,
    faComments,
    faPalette,
    faEdit,
    faTrashAlt,
    faShare,
    faCheckSquare,
    faTools,
    faCheckDouble,
    faSmileBeam,
    faPoll,
    faPercentage,
    faIcons,
    faList,
    faUserFriends,
    faExclamationCircle,
    faFolderPlus,
    faTag,
    faAt,
    faCalendarAlt,
    faCalendarCheck,
    faMapMarkerAlt,
    faCross,
    faCloud,
    faCalculator,

    // Brand
    faFacebook,
    faFacebookF,
    faFacebookSquare,
    faGulp,
    faNodeJs,
    faSass,
    faTwitter,
    faGooglePlusG,
    faLinkedinIn,
    faMediumM,
    faHotjar,
    faYoutube,
    faVideo,
    faInfo,
    faPhone,
    faTrello,

    // Regular
    farHeart,
    farLightbulb,
    farObjectUngroup,
    farPaperPlane,
    farStar,
    farImage,
    farFileArchive,
    farFilePdf,
    farFile,
    farFileVideo,
    farFileAudio,
    farFileImage,
    farFileCode,
    farClock,
    farQuestionCircle,
    farDizzy,
    farLaughBeam,
    farSmileBeam,
    farCircle,
    farCopy,
    farClone,
    farClipboard,
    farComment,
    faLongArrowAltLeft,
    faSimCard,
);
