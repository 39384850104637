
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/authContext/authContext";

export default function AuthenticatedRoute({ children, ...rest }) {
    const { pathname, search } = useLocation();
    const { authenticationCode } = useAuthContext();
    return (
        <Route {...rest}>
            {authenticationCode ? (
                children
            ) : (
                <Redirect to={
                    `/login?redirect=${pathname}${search}`
                } />
            )}
        </Route>
    );
}