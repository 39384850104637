import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';

const Layout = () => {
    return (
        <>
            <Switch>
                <Route component={DashboardLayout} />
            </Switch>
            <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
        </>
    );
};

export default Layout;
