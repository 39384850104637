import { useState, Fragment } from 'react';
import { FormGroup, Input, Label, Row, Form, Col, Card, Button, Alert } from 'reactstrap';
import { Image } from "react-bootstrap";
import { getAuth, EmailAuthProvider, updatePassword, reauthenticateWithCredential } from "firebase/auth";

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const auth = getAuth();
        auth.onAuthStateChanged(user => {
            if (user) {
                const credential = EmailAuthProvider.credential(
                    user.email,
                    currentPassword
                );


                reauthenticateWithCredential(user, credential)
                    .then(() => {
                        if (newPassword !== repeatNewPassword) {
                            setError('Passwords do not match');
                            return;
                        }
                        updatePassword(user, newPassword)
                            .then(() => {
                                setCurrentPassword('');
                                setNewPassword('');
                                setRepeatNewPassword('');
                                setError('');
                                console.log('Password changed successfully');
                            })
                            .catch((error) => {
                                setError(error.message);
                            })
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            } else {
                setError('Current user is not logged in');
            }
        });
    };

    return (
        <Row className="justify-content-center">
            <Col md="4" className="pt-4 pb-1">
                <Card className="pt-4 pb-1 ">
                    <Row> <Col />
                        <Col className="m-3"><Image src={process.env.PUBLIC_URL + "/images/Stansefabrikken_Logo.png"} /></Col>
                        <Col />
                    </Row>
                    <Col>

                        <Fragment>
                            <div className="mb-5">
                                <Form onSubmit={handleSubmit}>
                                    <Row className="align-items-center p-3" >
                                        <FormGroup>
                                            <Label>Current Password:</Label>
                                            <Input
                                                type="password"
                                                id="currentPassword"
                                                value={currentPassword}
                                                onChange={(event) => setCurrentPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row className="align-items-center p-3" >
                                        <FormGroup>
                                            <Label htmlFor="newPassword">New Password:</Label>
                                            <Input
                                                type="password"
                                                id="newPassword"
                                                value={newPassword}
                                                onChange={(event) => setNewPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row className="align-items-center p-3" >
                                        <FormGroup>
                                            <Label htmlFor="repeatNewPassword">Repeat New Password:</Label>
                                            <Input
                                                type="password"
                                                id="repeatNewPassword"
                                                value={repeatNewPassword}
                                                onChange={(event) => setRepeatNewPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Row>
                                    {error && <Alert color="danger">{error}</Alert>}
                                    <Button type="submit">Change Password</Button>
                                </Form>
                            </div>
                        </Fragment>
                    </Col>
                </Card>

            </Col>
        </Row>
    );
}


export default ChangePassword;