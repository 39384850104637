import React, { useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useAuthContext } from '../context/authContext/authContext';

import { LinkContainer } from 'react-router-bootstrap';

import { useHistory } from 'react-router-dom';
import DashboardRoutes from './DashboardRoutes';
import { Image } from 'react-bootstrap';
// import Cookies from 'universal-cookie';

function DashboardLayout({ location }) {
    const history = useHistory();
    // const cookies = new Cookies();

    const { authenticationCode, handleSignOut } = useAuthContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleLogout = () => {
        handleSignOut();
        history.push("/")
        // cookies.set('AuthenticationCode', '');
    };

    return (
        <div className="App py-3 px-2 px-sm-3 px-lg-5">
            <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
                <LinkContainer to="/">
                    <Navbar.Brand className="font-weight-normal text-muted"><Image className="mr-2" src={process.env.PUBLIC_URL + "/images/Stansefabrikken_Logo_Long.png"} /></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav activeKey={window.location.pathname}>
                        {authenticationCode ? (
                            <>
                                <LinkContainer to="/">
                                    <Nav.Link>Hjem</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/about">
                                    <Nav.Link>Om oss</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/changePassword">
                                    <Nav.Link>Endre passord</Nav.Link>
                                </LinkContainer>

                                <Nav.Link onClick={handleLogout}>Logg ut</Nav.Link>
                            </>
                        ) : (
                            <>
                                <LinkContainer to="/about">
                                    <Nav.Link>Om oss</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/login">
                                    <Nav.Link>Logg Inn</Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {/* Routes */}
            <DashboardRoutes />
        </div>
    );
}

export default DashboardLayout;
