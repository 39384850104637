import { USER_LOADED, LOGIN_SUCCESS, LOGOUT, LOGIN_FAIL, SEND_LOGIN_DETAILS_SUCCESS, SEND_LOGIN_DETAILS_FAIL, CLEAR_ERRORS } from '../types';
import { setCookie } from '../../utils/cookieHandlers';

const authReducer = (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload
            };

        case LOGIN_SUCCESS:
            const token = "Bearer " + action.payload;
            localStorage.setItem('token', token);
            setCookie('AuthenticationCode', token);
            return {
                ...state,
                authenticationCode: action.payload,
                isAuthenticated: true,
                error: null,
                loading: false
            };

        case LOGOUT:
            localStorage.setItem('token', '');
            localStorage.removeItem('token');
            localStorage.setItem('access_types', '');
            localStorage.removeItem('access_types');
            setCookie('AuthenticationCode', '', 0);
            return {
                ...state,
                token: null,
                authenticationCode: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: null
            };

        case SEND_LOGIN_DETAILS_SUCCESS:
            return {
                ...state,
                loginDetailsSent: action.payload,
                loginDetailsCalled: true
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loginDetailsCalled: false
            };

        case SEND_LOGIN_DETAILS_FAIL:
        case LOGIN_FAIL:
            console.log("LOGIN_FAIL", action.payload)
            return {
                ...state,
                error: "LOGIN_FAIL " +(action.payload ?? ""),
                loading: false
            };

        default:
            return state;
    }
};

export default authReducer;
