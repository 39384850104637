import { Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { Button, Input, FormText, Nav, NavItem, NavLink } from 'reactstrap';
import { useDebounce } from '../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { isIterableArray, getPaginationArray } from '../../../helpers/utils'
import AddressMap from '../map/AddressMap';


const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div>
            <Button className="float-right mb-2" color="success" onClick={handleClick}>Eksporter til CSV</Button>
        </div>
    );
};


const PostBoxes = ({ boxes }) => {

    const [columns, setColumns] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState();
    const debouncedFilter = useDebounce(filter, 300);
    const history = useHistory();
    const [activeTab, setActiveTab] = useState("list");

    useEffect(() => {
        let columns = [
            {
                dataField: 'building_name',
                text: 'Bygning',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'box_address',
                text: 'Postkasse-ID',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
            },
            {
                dataField: 'resident',
                text: 'Beboer',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
                formatter: (cell, row, rowIndex, extraData) => {
                    return cell?.replace(/(?:\\[rn])+/g, "");
                },
                csvFormatter: (cell, row, rowIndex, extraData) => {
                    return cell?.replace(/(?:\\[rn])+/g, "") ?? "";
                },
            },
            {
                dataField: 'box_serial',
                text: 'Serienummer',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
                csvFormatter: (cell, row, rowIndex, extraData) => {
                    return cell ?? "";
                },
            },
            {
                dataField: 'display_content',
                text: 'Tekst på displayet',
                headerClasses: 'border-0',
                classes: 'border-0 py-2 align-middle',
                sort: true,
                csvFormatter: (cell, row, rowIndex, extraData) => {
                    return cell ?? "";
                },
            },
        ];

        setColumns(columns);

        // eslint-disable-next-line
    }, [boxes]);


    useEffect(() => {
        if (!debouncedFilter) {
            setFiltered(boxes);
        }
        else {
            const columnNames = columns.map(c => c.dataField);
            const filtered = (boxes ?? []).filter(m => {
                const searchValue = debouncedFilter.toLowerCase();
                const values = Object.entries(m).filter(([k, v]) => {
                    return columnNames.includes(k);
                }
                )
                return values.some(([k, v]) => String(v).toLowerCase().indexOf(searchValue) >= 0);

            })
            setFiltered(filtered);
        }
    }, [debouncedFilter, boxes, columns]);

    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
    };

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    };

    if (!boxes || !isIterableArray(columns)) {
        return null;
    }


    const tableOptions = {
        onClick: (e, row, rowIndex) => {
            history.push(`/postbox/${row.box_id}`);
        }
    }


    return (
        <ToolkitProvider
            keyField="box_id"
            data={(filtered ?? [])}
            columns={columns}
            length={filtered?.length ?? 0}
            exportCSV={{
                fileName: 'postkasser.csv',
                ignoreFooter: true,
                onlyExportFiltered: true,
                exportAll: true
            }
            } >
            {props => (<div>
                <Row>
                    <Col style={{ minWidth: '200px' }}>
                        <Input
                            type="search"
                            autoFocus
                            placeholder="Filter"
                            aria-label="Filter"
                            className="rounded-pill search-input"
                            value={filter}
                            onChange={({ target }) => setFilter(target.value)}
                        />
                        <FormText className="ml-4" muted>{`${filtered?.length} / ${boxes?.length} Postkasser`}</FormText>
                    </Col>
                    <Col xs="auto">
                        <MyExportCSV {...props.csvProps} />
                    </Col>
                </Row>
                <Button onClick={() => { history.push(`/postbox/new`); }} className="m-2 fs--1 rounded-pill" size="sm" color="outline-primary">
                    <FontAwesomeIcon icon="plus" />
                    {" Legg til"}
                </Button>
                <PaginationProvider pagination={paginationFactory({
                    custom: true,
                    sizePerPage: 50,
                    totalSize: isIterableArray(filtered) && filtered.length
                })}>
                    {({ paginationProps, paginationTableProps }) => {
                        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

                        return (<>

                            <Nav tabs>
                                <NavItem className='cursor-pointer'>
                                    <NavLink active={activeTab === 'list'} onClick={() => setActiveTab('list')}>Liste</NavLink>
                                </NavItem>
                                {/* <NavItem className='cursor-pointer'>
                                    <NavLink active={activeTab === 'map'} onClick={() => setActiveTab('map')}>Kart</NavLink>
                                </NavItem> */}

                            </Nav>
                            {activeTab === 'list' &&
                                <div className="table-responsive  mb-4">
                                    <BootstrapTable
                                        {...props.baseProps}
                                        hover
                                        bordered={false}
                                        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-left border-right border-200"
                                        rowClasses="btn-reveal-trigger border-top border-200 cursor-pointer"
                                        headerClasses="bg-200 text-900 border-y border-200"
                                        rowEvents={tableOptions}
                                        defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                                        {...paginationTableProps}

                                    />
                                </div>}
                            {activeTab === 'list' && isIterableArray(filtered) && filtered.length > 20 &&
                                <Row noGutters className="px-1 py-3 flex-center">
                                    <Col xs="auto">
                                        <Button
                                            color="falcon-default"
                                            size="sm"
                                            onClick={handlePrevPage(paginationProps)}
                                            disabled={paginationProps.page === 1}
                                        >
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </Button>
                                        {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                                            <Button
                                                color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                                                size="sm"
                                                className="ml-2"
                                                onClick={() => paginationProps.onPageChange(pageNo)}
                                                key={pageNo}
                                            >
                                                {pageNo}
                                            </Button>
                                        ))}
                                        <Button
                                            color="falcon-default"
                                            size="sm"
                                            className="ml-2"
                                            onClick={handleNextPage(paginationProps)}
                                            disabled={lastIndex >= paginationProps.totalSize}
                                        >
                                            <FontAwesomeIcon icon="chevron-right" />
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            {activeTab === 'map' &&
                                <div>
                                    <AddressMap
                                        addresses={
                                            Object.values(boxes.map(value => {
                                                return {
                                                    address: (value.street ?? '') + ' ' + (value.zip ?? '') + ' ' + (value.city ?? ''),
                                                    name: value.box_address
                                                }
                                            }).reduce((acc, curr) => {
                                                if (!acc[curr.address]) {
                                                    acc[curr.address] = {
                                                        address: curr.address,
                                                        name: [curr.name],
                                                    };
                                                } else {
                                                    acc[curr.address].name = `${acc[curr.address].name} , ${curr.name}`;
                                                }
                                                return acc;
                                            }, {}))
                                        }
                                    />
                                </div>

                            }
                        </>
                        );
                    }}

                </PaginationProvider>
            </div>)}
        </ToolkitProvider>
    );
}

export default PostBoxes;