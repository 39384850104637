import { Card, Row, Col, FormControl, Button, Form, FormGroup, Alert } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import Select from 'react-select';
import { fetchBuildings, fetchPostBox, fetchBoxSerials, fetchBoxAccesses } from '../../../data/dataFetch';
import { updatePostBox, createPostBox, deletePostBox, deleteAccess , unlockPostbox } from '../../../data/dataUpdate';
import { isIterableArray } from "../../../helpers/utils";
import confirm from 'reactstrap-confirm';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';
import TableSearchable from "../../common/TableSearchable";

const PostBox = () => {

    const { id } = useParams();
    const [isNew, setIsNew] = useState(true);
    const [isEditing, setIsEditing] = useState(true);
    const [initialPostBox, setInitialPostBox] = useState({ building_name: '', box_address: '', building_id: 0, box_serial: '', display_content: '', is_common_box: false, box_height: 0, box_width: 0, box_length: 0, box_serial_deleted: null });
    const [postBox, setPostBox] = useState(initialPostBox);
    const [buildings, setBuildings] = useState(null);
    const [boxSerials, setBoxSerials] = useState(null);
    const [isCommonBox, setIsCommonBox] = useState(false);
    const [accesses, setAccesses] = useState([]);
    const history = useHistory();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        const loadBuildings = async () => {
            setBuildings(await fetchBuildings());
        }
        loadBuildings();
    }, [])

    useEffect(() => {
        const loadPostBox = async () => {
            var data = await fetchPostBox(id);
            var copy = JSON.parse(JSON.stringify(data));
            setIsNew(false);
            setInitialPostBox(copy);
            setPostBox(copy);
            loadBoxSerials(copy.building_id);
            loadAccesses(id)
        }
        if (id === 'new') {
            setInitialPostBox({ building_name: '', box_address: '', building_id: 0, box_serial: '', display_content: '', is_common_box: false, box_height: 0, box_width: 0, box_length: 0 });
            setPostBox(initialPostBox);
            setIsNew(true);
        }
        else
            loadPostBox();
        // eslint-disable-next-line
    }, [])

    const isPostBoxUnchanged = () => {
        return (JSON.stringify(initialPostBox) === JSON.stringify(postBox));
    }

    const loadAccesses = async (boxID,) => {
        var data = (await fetchBoxAccesses(boxID)) ?? [];
        setAccesses(data);
    }

    const loadBoxSerials = async (buildingId) => {
        setBoxSerials(await fetchBoxSerials(buildingId));
    }

    const insertOrUpdatePostBox = async () => {
        if (isNew) {
            const result = await createPostBox(postBox);
            if (result) {
                setIsEditing(false);
                setInitialPostBox(postBox);
                toast.success('bygning er lagt til');
            }
            else
                toast.error('Det har oppstått en feil ', result);
        }
        else {
            updatePostBox(postBox);
            setIsEditing(false);
            setInitialPostBox(postBox);
            toast.success('postkasse er oppdatert');
        }
    }

    const removeSerialNumber = async () => {
        postBox.box_serial_deleted = postBox.box_serial;
        postBox.box_serial = null;
        updatePostBox(postBox);
        setIsEditing(false);
        forceUpdate();
    }


    const unlockBox = async(id) => {
        unlockPostbox(id);
    }


    const deleteCurrentPostbox = async (id) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            await deletePostBox(id);
            history.push(`/`);
        }

    }
    const deleteCurrentAccess = async (accessId) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            await deleteAccess(accessId);
            loadAccesses(id);
        }
    };


    const accessColumns = [
        {
            accessor: 'access_name',
            Header: 'Tilgangsnavn',
        },
        {
            accessor: 'full_name',
            Header: 'Beboer',
        },
        {
            accessor: 'device_id',
            Header: 'Enhets-ID',
        },
        {
            accessor: 'device_type',
            Header: 'Enhets-type',
        },
        {
            accessor: 'valid_from',
            Header: 'Gyldig fra',
        },
        {
            accessor: 'valid_to',
            Header: 'Gyldig til',
        },
        {
            Header: 'Gyldig',
            accessor: (row) => row.is_valid ? 1 : 0,
            Cell: props => {
              const row = props.row.original;
              return <div style={{ textAlign: 'center' }}>
                <input type="checkbox" checked={row.is_valid}></input>
              </div>;
            },           
        },
        {
            Headers: '',
            accessor: 'access_id',
            Cell: props => {
                const row = props.row.original;
                return <div style={{ textAlign: 'center' }} >
                    <Button
                        onClick={() => { deleteCurrentAccess(row.access_id) }}
                        color="outline-primary" size="sm" >
                        <FontAwesomeIcon icon="trash" />
                    </Button>
                </div>;
            },
            maxWidth: 50
        }

    ]

    return (
        <div className="mb-5">


            <Card>
                <Row className="align-items-center p-3" >
                    <Col>
                        <h5>{isNew ? "NY" : ""} POSTKASSE {!isNew ? postBox.box_address : ""}</h5>
                    </Col>
                    <Col xs="auto" className="text-right">
                        <Link to={`/`} className="mr-2">
                            <Button variant="primary" size="sm"> &lt; Postkasser</Button>
                        </Link>
                        {!isNew &&
                            < Button
                                onClick={() => { deleteCurrentPostbox(id); }}
                                variant="danger" size="sm">Slett
                            </Button>
                        }
                        {" "}
                        < Button
                            onClick={() => {
                                if (isEditing) {
                                    setPostBox(JSON.parse(JSON.stringify(initialPostBox)));
                                    if (isNew) {
                                        history.push(`/`);
                                    }
                                }
                                setIsEditing(!isEditing);
                            }}
                            variant="warning" size="sm" >Avbryt
                        </Button>
                    </Col>
                </Row>
                <Card.Body>
                    <Form>
                        <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                            <Form.Label>Bygning</Form.Label>
                            <Select
                                options={
                                    isIterableArray(buildings) && Object.values(buildings).map((building) => ({ value: building.building_id, label: building.building_name }))
                                }
                                value={({ value: postBox.building_name, label: isIterableArray(buildings) ? (buildings.find(v => v.building_id === Number(postBox.building_id)) ?? {}).building_name : postBox.building_name })}
                                onChange={({ value }) => {
                                    var building = buildings.find(v => v.building_id === Number(value));
                                    setPostBox({ ...postBox, building_id: value, building_name: building.building_name });
                                    loadBoxSerials(value);
                                }}
                            />

                        </FormGroup>
                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Postkasse-ID</Form.Label>
                            <FormControl
                                value={postBox.box_address}
                                onFocus={(event) => { event.target.select() }}
                                onChange={({ target }) => {
                                    setPostBox({ ...postBox, box_address: target.value })
                                }}
                            />

                        </FormGroup>

                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Serienummer</Form.Label>
                            <Select
                                options={isIterableArray(boxSerials) ? boxSerials.map((serial) => ({ value: serial, label: serial })) : []}
                                value={({ value: postBox.box_serial, label: postBox.box_serial })}
                                onChange={({ value }) => {
                                    setPostBox({ ...postBox, box_serial: value });
                                }}
                            />
                        </FormGroup>


                        <FormGroup className="mr-2 mb-2">
                            <Form.Label>Tekst på displayet</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={postBox.display_content}
                                onFocus={(event) => { event.target.select() }}
                                onChange={({ target }) => {
                                    setPostBox({ ...postBox, display_content: target.value })
                                }}
                                className="w-100"
                            />

                        </FormGroup>

                        <FormGroup className="mr-2 mb-2">
                            <Row>
                                <Col>
                                    <Form.Label>Felles boks</Form.Label>
                                    <Form.Control
                                        type="checkbox"
                                        checked={postBox.is_common_box}
                                        onChange={({ target }) => {
                                            setPostBox({ ...postBox, is_common_box: target.checked })
                                            setIsCommonBox(target.checked);
                                        }}
                                    />
                                </Col>
                                <Col>

                                    <Form.Label>Høyde</Form.Label>
                                    <FormControl
                                        type="number"
                                        value={postBox.box_height}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setPostBox({ ...postBox, box_height: Number(target.value) })
                                        }}
                                        disabled={!isCommonBox}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Bredde</Form.Label>
                                    <FormControl
                                        type="number"
                                        value={postBox.box_width}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setPostBox({ ...postBox, box_width: Number(target.value) })
                                        }}
                                        disabled={!isCommonBox}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Lengde</Form.Label>
                                    <FormControl
                                        type="number"
                                        value={postBox.box_length}
                                        onFocus={(event) => { event.target.select() }}
                                        onChange={({ target }) => {
                                            setPostBox({ ...postBox, box_length: Number(target.value) })
                                        }}
                                        disabled={!isCommonBox}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </Card.Body>
                <Row className="align-items-center p-4" >
                    {!isNew &&
                        <>
                            <Col xs="auto" className="text-right">

                                {" "}
                                < Button
                                    onClick={() => { removeSerialNumber(); }}
                                    color="outline-primary" size="sm" >
                                    <FontAwesomeIcon icon="trash" className="mr-1" />
                                    Fjern serienummer fra postkassen
                                </Button>
                                {" "}
                            </Col>
                            <Col xs="auto" className="text-right">
                                <Button
                                    as={Link}
                                    color="outline-primary"
                                    size="sm"
                                    to={`/postbox/${id}/add-access`}
                                >
                                    <FontAwesomeIcon icon="plus" className="mr-1"/>
                                    Legg til korttilgang
                                </Button>
                            </Col>
                            <Col xs="auto" className="text-right">
                                <Button
                                    onClick={() => { unlockBox(id); }}
                                    color="outline-primary"
                                    size="sm"                                   
                                >
                                    <FontAwesomeIcon icon="lock" className="mr-1" /> 
                                     Låse opp
                                </Button>
                            </Col>                            
                        </>
                    }
                </Row>
                <>
                    {!isNew &&
                        < div className="ml-3 mr-3 mb-2">
                            <TableSearchable
                                columns={accessColumns}
                                data={accesses
                                    && accesses.map(value => {
                                        if (value) {
                                            const full_name = (value?.first_name ?? '') + ' ' + (value?.last_name ?? '');
                                            return {
                                                ...value,
                                                full_name: full_name
                                            }
                                        }
                                        return null;
                                    })
                                }
                                size={100}

                            />
                        </div>
                    }
                </>

            </Card>

            {
                isPostBoxUnchanged() ? null : <div className="fixed-top">
                    <Alert color="danger">
                        <Row>
                            <Col sm="auto">
                                <Button onClick={() => insertOrUpdatePostBox()} variant="primary" >
                                    Bruk
                                </Button>
                                {" "}
                                {!isNew &&
                                    <Button variant="danger" >
                                        Tilbakstil
                                    </Button>
                                }
                            </Col>
                            <Col className="mt-2" sm="auto">
                                <span >
                                    Vil du lagre endringene?
                                </span>
                            </Col>
                        </Row>
                    </Alert>
                </div>
            }

        </div >

    );

};
export default PostBox;