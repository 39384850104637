import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import classNames from 'classnames';
import PackageJson from "../../../package.json"
import { Image } from "react-bootstrap";
import React from 'react';

export default function About() {
    const at = "@";
    const emailAddress = 'support' + at + 'iots.no';
    return <div className="About">

        <Row className="justify-content-center">
            <Col md="4" className="pt-4 pb-1">

                <Card>
                    <CardHeader className={classNames({ 'bg-light': true })}>
                        <Row> <Col />
                            <Col className="m-3"><Image src={process.env.PUBLIC_URL + "/images/Stansefabrikken_Logo.png"} /></Col>
                            <Col />
                        </Row>
                        <h5 align="center">Versjon {PackageJson.version} </h5>
                    </CardHeader>
                    <CardBody>
                        <article align="center">
                            <p><strong>STANSEfabrikken Home</strong> er et verktøy utviklet av IOT Solutions som administrer postkasser.</p>
                            <br />
                            <a href="./PrivacyPolicy">Home Solution Privacy Policy</a>
                            <br/>
                            <br/>
                            <small><p>Kontakt oss: &nbsp;<a href={"mailto: " + emailAddress}>{emailAddress}</a></p>
                                <p>Besøk nettsted vårt: &nbsp;<a href="https://www.iotsolutions.no">www.iotsolutions.no</a></p></small>
                        </article>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div >;

}