import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Card, Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SerialPortController } from '../../../utils/cardReader'
import { fetchBoxResidents } from '../../../data/dataFetch';
import { useParams } from "react-router-dom";
import { isIterableArray } from '../../../helpers/utils';
import { useHistory } from "react-router-dom";
import { createAccess } from '../../../data/dataUpdate';

const NewCardAccess = () => {
    const { id } = useParams();
    const history = useHistory();

    const addAccessBtn = useRef(null);
    const exitAccessFormBtn = useRef(null);
    const btnConnect = useRef(null);
    const inputCardSerial = useRef(null);
    const serialPortController = new SerialPortController();

    const [boxResidents, setBoxResidents] = useState([]);
    const [accessName, setAccessName] = useState('')
    const [residentID, setResidentID] = useState('');
    // const [cardID, setCardID] = useState('');
    const [validFrom, setValidFrom] = useState('')
    const [validTo, setValidTo] = useState('')

    const [validated, setValidated] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);

    const getBoxResidents = async (boxID) => {
        setBoxResidents(await fetchBoxResidents(id))
    }

    useEffect(() => {
        getBoxResidents(id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (accessName && validFrom && validTo && residentID) {
            setCanSubmit(true);

        } else {
            setCanSubmit(false);
        }
        // eslint-disable-next-line
    }, [accessName, validFrom, validTo, residentID]);


    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        console.log(form.checkValidity())
        if (form.checkValidity() && canSubmit) {
            const access = {
                access_name: accessName,
                access_token: inputCardSerial.current.value,
                device_id: inputCardSerial.current.value,
                box_id: parseInt(id),
                device_type: 'Smartcard',
                resident_id: residentID,
                user_id: boxResidents.residents.filter(v => v.resident_id === residentID)[0].user_id,
                valid_from: new Date(validFrom).toISOString(),
                valid_to: new Date(validTo).toISOString()
            }
            
            createAccess(access)
            setValidated(false);
            
            // Reset values
            inputCardSerial.current.value = ''
        }
    };


    useEffect(() => {
        exitAccessFormBtn.current.addEventListener('pointerdown', () => {
            console.log("CLOSING CONNECTION")
            serialPortController.close();
        });

        btnConnect.current.addEventListener('pointerdown', () => {
            serialPortController.init();
        });

        async function get_card_serial() {
            if (serialPortController.connected) {
                btnConnect.current.disabled = true;

                let a = await serialPortController.read();

                if (a) {
                    inputCardSerial.current.value = a;
                    // setCardID(a)
                }
            } else btnConnect.current.disabled = false;
        }

        function looper() {
            get_card_serial();
        }

        var interval = setInterval(looper, 1000);
        var timeout = setTimeout(looper, 500);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
            if (serialPortController.connected) {
                serialPortController.close()
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card>
            <Card.Header><h5>Ny korttilgang</h5></Card.Header>
            <Card.Body>
                <Row className='mb-2'>
                    <Col className='d-flex justify-content-center'>
                        <Button
                            variant="secondary"
                            size="sm"
                            id="sc_b_sai_t_exit"
                            disabled={!serialPortController.connected}
                            // onClick={handleCancelClick}
                            style={{ marginLeft: '0.2rem', marginRight: '0.2rem' }}
                            ref={exitAccessFormBtn}
                        >
                            <FontAwesomeIcon icon="ban" /> Koble fra kortleser
                        </Button>
                        <Button
                            variant="success"
                            size="sm"
                            id="id_btn_connect"
                            disabled={serialPortController.connected}
                            type="button"
                            style={{ marginLeft: '0.2rem' }}
                            ref={btnConnect}
                        >
                            <FontAwesomeIcon icon="sim-card" /> Aktiver kortleser
                        </Button>
                    </Col>
                </Row>

                <Form validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Label htmlFor='access_name'>Tilgangsnavn</Form.Label>
                        </Col>
                        <Col md={12} >
                            <FormControl
                                id="access_name"
                                className="border-secondary"
                                type="text"
                                style={{ backgroundColor: 'white' }}
                                placeholder="Ola Nordmann tilgang"
                                name="access_name"
                                value={accessName}
                                onChange={e => setAccessName(e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label >Beboer</Form.Label>
                        </Col>
                        <Col md={12} >
                            <Form.Select
                                className="w-100 border-secondary form-control form-control-md"
                                size="lg"
                                name="resident"
                                value={residentID}
                                onChange={e => setResidentID(parseInt(e.target.value))}
                                required
                            >
                                <option key={`0`} value={``}>{``}</option>
                                {isIterableArray(boxResidents?.residents) && boxResidents.residents.map(v => <option key={v.resident_id} value={v.resident_id}>{`${v.first_name} ${v.last_name}`}</option>)}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label >Enhets-ID</Form.Label>
                        </Col>
                        <Col md={12} >
                            <FormControl
                                className="border-secondary"
                                type="text"
                                id="id_card_serial"
                                style={{ backgroundColor: 'white' }}
                                name="access_t" required
                                ref={inputCardSerial}
                                onChange={e => inputCardSerial.current.value = e.target.value}
                            // value={cardID}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label >Gyldig fra</Form.Label>
                        </Col>
                        <Col md={12} >
                            <FormControl
                                className="border-secondary"
                                type="datetime-local"
                                name="valid_from"
                                value={validFrom}
                                onChange={e => setValidFrom(e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <Form.Label >Gyldig til</Form.Label>
                        </Col>
                        <Col md={12} >
                            <FormControl
                                className="border-secondary"
                                type="datetime-local"
                                name="valid_to"
                                value={validTo}
                                onChange={e => setValidTo(e.target.value)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={7} ></Col>
                    </Row>

                    <Row className="justify-content-center mt-3">
                        <Col md={7} className="text-center" >
                            <Button
                                className='mr-1'
                                variant="primary"
                                size="sm"
                                id="sc_b_insert_access"
                                type='submit'
                                ref={addAccessBtn}
                            >
                                <FontAwesomeIcon icon="plus" /> Lagre
                            </Button>
                            <Button
                                className='ml-1'
                                variant="danger"
                                size="sm"
                                id="id_btn_connect-1"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                {/* <FontAwesomeIcon icon={["fa", "long-arrow-alt-left"]} /> Avbryt */}
                                <FontAwesomeIcon icon={["fa", "times"]} /> Avbryt
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default NewCardAccess