import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ActionButton = ({ placement = 'top', title, icon, sizeClass, ...rest }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{title}</Tooltip>}>
      <Button {...rest}>
        <FontAwesomeIcon icon={icon} className={`${sizeClass}`} />
      </Button>
    </OverlayTrigger>
  );
};

ActionButton.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  title: PropTypes.string.isRequired,
  // icon: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  sizeClass: PropTypes.oneOf(["", "fa-xs", "fa-sm", "fa-lg", "fa-2x", "fa-3x", "fa-5x"])
};

ActionButton.defaultProps = {
  layout: 'simple',
  // hasLabel: false,
  sizeClass: ''
};

export default ActionButton;
