
// /////////////////////////////////////////////////////////////////////////////////////
// Serial port controller class
//
export class SerialPortController {
    constructor() {
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
        this.connected = false;
        this.error_count = 0;
    }

    // /////////////////////////////////////////////////////////////////////////////////////
    // Initializew serial port using pop-up
    //
    async init() {
        if ('serial' in navigator) {
            if (!this.connected) {
                try {
                    // Filter on devices with the RFID
                    const filter = { usbVendorId: 0x09D8, usbProductId: 0x0420 };
                    // const ports = await navigator.serial.getPorts();
                    this.port = await navigator.serial.requestPort({ filters: [filter] });
                    await this.port.open({ baudRate: 115200 });
                    this.reader = this.port.readable.getReader();
                    this.connected = true
                } catch (err) {
                    console.error('There was an error opening the serial port:', err);
                }
            }
        } else {
            const errorMessage = 'Web serial doesn\'t seem to be enabled in your browser. '// + ' ' +
                // 'Try enabling it by visiting:\n\n' +
                // 'chrome://flags/#enable-experimental-web-platform-features\n' +
                // 'opera://flags/#enable-experimental-web-platform-features\n ' +
                // 'edge://flags/#enable-experimental-web-platform-features';

            document.getElementById('id_message').style = "";
            alert(errorMessage);
            console.error(errorMessage);
        }
    }

    // /////////////////////////////////////////////////////////////////////////////////////
    // Read data from serial port and return as text string
    //
    async read() {
        try {
            const readerData = await this.reader.read();
            return this.decoder.decode(readerData.value);
        } catch (err) {
            this.error_count++;
            const errorMessage = 'Error reading data (' + this.error_count + '): ' + err;
            alert(errorMessage);
            console.error(errorMessage);


            if (this.error_count >= 1) this.close();
            return '';
        }
    }

    async close() {
        this.connected = false
        this.error_count = 0;
        await this.reader.cancel();
        await this.port.close();
    }

}

/*

// /////////////////////////////////////////////////////////////////////////////////////
// Initialze serial port xcontroller and variables, buttons and fields
//
export const serialPortController = new SerialPortController();
const btn_connect = document.getElementById('id_btn_connect');
const input_card_serial = document.getElementById('id_card_serial');
//const btn_add = document.getElementById('id_btn_add');

// Add event listeners
// btn_connect.addEventListener('pointerdown', () => { serialPortController.init(); });
//input_card_serial.addEventListener('keyup', () => { enable_add(); });
//input_card_serial.addEventListener('change', () => { enable_add(); });

// Enable/disable 'Add card' button based on input fields text length
// // ---------- UNCOMMENT ????? ___________
// function enable_add() {
//     btn_add.disabled = (input_card_serial.value.length < 6);
// }

// Read from serial port (if connected) and put text into input field on web page
async function get_card_serial() {
    if (serialPortController.connected) {
        btn_connect.disabled = true;

        let a = await serialPortController.read();

        input_card_serial.value = a;
        //enable_add();
    } else btn_connect.disabled = false;

}

// Loop function to be called at regular intervals
function looper() {
    // get_card_serial();
}

var interval = setInterval(looper, 1000);
var timeout = setTimeout(looper, 500);

if (!('serial' in navigator)) {
    document.getElementById('id_message').style = "";
    document.getElementById('id_reader_stuff').style = "display:none";
}

*/ 