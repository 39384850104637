import Cookies from "universal-cookie/es6";

export const setCookie = (key, value, maxAge = 3600) => {
    const cookies = new Cookies();
    const cookieSettings = {
        maxAge: maxAge,
        sameSite: true
    };
    cookies.set(key, value, cookieSettings);
}

export const getCookie = (key) => {
    const cookies = new Cookies();
    cookies.get(key);
}