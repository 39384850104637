const config =
{
    APPLICATION_URL: window.app && window.app.env.APPLICATION_URL,
    development: window.app && window.app.env.development,
    firebaseApiKey: window.app &&  window.app.env.firebaseApiKey,
    firebaseAuthDomain: window.app && window.app.env.firebaseAuthDomain,
    firebaseProjectId: window.app && window.app.env.firebaseProjectId,
    firebaseStorageBucket: window.app && window.app.env.firebaseStorageBucket,
    firebaseMessagingSenderId: window.app && window.app.env.firebaseMessagingSenderId,
    firebaseAppId: window.app && window.app.env.firebaseAppId
};

export const version = '1.0.1';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    isTopNav: false,
    isVertical: true,
    get isCombo() {
        return this.isVertical && this.isTopNav;
    },
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false,
    navbarStyle: 'transparent'
};

export default config;