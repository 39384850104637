import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from '../../context/authContext/authContext';

export function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute(props) {
    const { children, ...rest } = props;
    const { authenticationCode } = useAuthContext();
    const redirect = querystring("redirect");
    return (
        <Route {...rest}>
            {!authenticationCode ? (
                children
            ) : (
                <Redirect to={redirect === "" || redirect === null ? "/home" : redirect} />
            )}
        </Route>
    );
}