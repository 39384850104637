import { Card, CardBody, Row, Col, Input, Button, Label, FormGroup, Alert } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { fetchAllCustomers, fetchCustomer } from '../../../data/dataFetch';
import React, { useState, useEffect } from 'react';
import { updateCustomer, createCustomer, deleteCustomer } from '../../../data/dataUpdate';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import confirm from 'reactstrap-confirm';
import { isIterableArray } from "../../../helpers/utils";
import { toast } from 'react-toastify';

const Customer = () => {

    const { id } = useParams();
    const [isNew, setIsNew] = useState(true);
    const [isEditing, setIsEditing] = useState(true);
    const [initialCustomer, setInitialCustomer] = useState({ parent_customer_id: 0, customer_name: '' });
    const [customer, setCustomer] = useState(initialCustomer);
    const [customers, setCustomers] = useState(null);
    const history = useHistory();


    useEffect(() => {
        const loadCustomer = async () => {
            var data = await fetchCustomer(id);
            var copy = JSON.parse(JSON.stringify(data));
            setIsNew(false);
            setInitialCustomer(copy);
            setCustomer(copy);
        }
        if (id === 'new') {
            setInitialCustomer({ parent_customer_id: 0, customer_name: '' });
            setCustomer(initialCustomer);
            setIsNew(true);
        }
        else
            loadCustomer();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomers(await fetchAllCustomers());
        }
        loadCustomers();
    }, [])

    const isCustomerUnchanged = () => {
        return (JSON.stringify(initialCustomer) === JSON.stringify(customer));

    }


    const insertOrUpdateCustomer = async () => {
        if (isNew) {
            const result = await createCustomer(customer);
            if (result){
                setIsEditing(false);
                setInitialCustomer(customer);
                toast.success('kunde er lagt til'); 
            }
            else
                toast.error('Det har oppstått en feil:', result);            
        } else{
            updateCustomer(customer);
            setIsEditing(false);
            setInitialCustomer(customer);
            toast.success('kunde er oppdatert');

        }
    }

    const deleteCurrentCustomer = async (id) => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            await deleteCustomer(id);
            history.push(`/`);
        }

    }


    return (
        <div className="mb-5">


            <Card>
                <Row className="align-items-center p-3" >
                    <Col>
                        <h5>{isNew ? "NY" : ""} KUNDE {!isNew ? customer.customer_name : "" }</h5> 
                    </Col>
                    <Col xs="auto" className="text-right">
                        <Link to={`/`} className="mr-2">
                            <Button color="primary" size="sm"> &lt; Kunder</Button>
                        </Link>
                        {!isNew && 
                        < Button
                            onClick={() => { deleteCurrentCustomer(customer.customer_id); }}
                            color="danger" size="sm">Slett
                        </Button> }
                        {" "}
                        <Button
                            onClick={() => {
                                if (isEditing) {
                                    setCustomer(JSON.parse(JSON.stringify(initialCustomer)));
                                }
                                setIsEditing(!isEditing);
                            }}
                            color="warning" size="sm" >Avbryt
                        </Button>
                    </Col>
                </Row>
                <CardBody>

                    <FormGroup className="mr-2 mb-2" style={{ minWidth: 200 }}>
                        <Label>Eier</Label>
                        <Select
                            options={
                                isIterableArray(customers) && Object.values(customers).map((customer) => ({ value: customer.customer_id, label: customer.customer_name }))
                            }
                            value={({ value: customer.parent_customer_id, label: isIterableArray(customers) ? (customers.find(v => v.customer_id === Number(customer.parent_customer_id)) ?? {}).customer_name : '' })}
                            onChange={({ value }) => {
                                var selectedCustomer = customers.find(v => v.customer_id === Number(value));
                                setCustomer({ ...customer, parent_customer_id: value, parent_customer_name: selectedCustomer.customer_name });
                            }}
                        />
                    </FormGroup>


                    <FormGroup className="mr-2 mb-2">
                        <Label>Kunde</Label>
                        <Input
                            value={customer.customer_name}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setCustomer({ ...customer, customer_name: target.value })
                            }}
                        />
                    </FormGroup>

                </CardBody>
            </Card>

            {
                isCustomerUnchanged() ? null : <div className="fixed-top">
                    <Alert color="danger">
                        <Row>
                            <Col sm="auto">
                                <Button onClick={() => insertOrUpdateCustomer()} color="primary" >
                                    Bruk
                                </Button>
                                {" "}
                                {!isNew &&
                                    <Button color="danger" >
                                        Tilbakstil
                                    </Button>
                                }
                            </Col>
                            <Col className="mt-2" sm="auto">
                                <span >
                                    Vil du lagre endringene?
                                </span>
                            </Col>
                        </Row>
                    </Alert>
                </div>
            }

        </div >

    );

};

export default Customer;
