import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SEND_LOGIN_DETAILS_SUCCESS, SEND_LOGIN_DETAILS_FAIL, CLEAR_ERRORS } from '../types';
import config from '../../config';
import firebase from "./firebase";
import { useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";


const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        authenticationCode: localStorage.getItem('token'),
        isAuthenticated: null,
        loading: false,
        loginDetailsSent: false,
        loginDetailsCalled: false,
        error: null
    };

    const [state, dispatch] = useReducer(authReducer, initialState);
    let history = useHistory();

    useEffect(() => {
        //Refresh Token
        const auth = getAuth();
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const tokenResult = await user.getIdTokenResult(true);
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: tokenResult.token,
                    });
                } catch (error) {
                    console.log(error);
                    console.log('log out...');
                    handleSignOut();
                    history.push("/")
                }

            }

        });
        // eslint-disable-next-line
    }, []);

    const clearErrors = () => {
        dispatch({
            type: CLEAR_ERRORS
        });
    };

    async function handleSignOut() {
        dispatch({ type: 'LOGOUT' });
    }




    // Load User
    // const loadUser = async () => {
    //   setAuthToken(localStorage.token);
    // };

    // Login User
    // const login = async (userName, password) => {
    //     const token = `${userName}:${password}`;
    //     const encodedToken = Buffer.from(token).toString('base64');

    //     const options = {
    //         url: config.APPLICATION_URL + 'api-key/admin',
    //         method: 'post',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Basic ' + encodedToken
    //         },

    //         data: {
    //             "app_name": "Homesolution Admin",
    //             "aud": "homesolution_admin",
    //             "customer_id": 0,
    //         }
    //     };
    //     try {
    //         const res = await axios(options);
    //         dispatch({
    //             type: LOGIN_SUCCESS,
    //             payload: res.data
    //         });
    //     } catch (err) {
    //         dispatch({
    //             type: LOGIN_FAIL,
    //             payload: err.response.data.message
    //         });
    //     }
    // };


    // Login User
    const login = async (email, password) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                user.getIdToken().then((idToken) => {
                    // idToken is the user's ID token
                    console.log("idToken:", idToken);
                    console.log(idToken);
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: idToken,
                    });
                });

            })
            .catch((error) => {
                console.log("error", error);
                dispatch({
                    type: LOGIN_FAIL,
                    payload: error,
                });

            });

    };


    const forgotLoginDetails = async (email) => {
        console.log('FORGOT_LOGIN_DETAILS');
        const options = {
            url: config.EPM_URL + 'rpc/forgot_login_details',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: {
                user_or_email: email
            }
        }
        try {
            const res = await axios(options);
            dispatch({
                type: SEND_LOGIN_DETAILS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: SEND_LOGIN_DETAILS_FAIL,
                payload: err.response ? err.response.data.message : err
            });
        }
    }

    // Logout
    const logout = () => dispatch({ type: LOGOUT });

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                authenticationCode: state.authenticationCode,
                loading: state.loading,
                error: state.error,
                loginDetailsSent: state.loginDetailsSent,
                loginDetailsCalled: state.loginDetailsCalled,
                login,
                logout,
                forgotLoginDetails,
                clearErrors,
                handleSignOut
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
