import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table';
import {
  TableFooter,
  // TablePagination,
  TableSearchBox
} from './AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from './ActionButton';


// Create an editable cell renderer
// eslint-disable-next-line no-unused-vars
const ActionCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(initialValue);

  // const onChange = e => {
  //   setValue(e.target.value);
  // };

  // // We'll only update the external data when the input is blurred
  // const onBlur = () => {
  //   updateMyData(index, id, value);
  // };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // return <input value={value} onChange={onChange} onBlur={onBlur} />;
  // <td className="text-end">
  //   <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
  //   <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
  // </td>;
};


// const defaultColumn = {
//   Cell: ActionCell,
// };


function TableSearchable({ columns, data, size, allowAdd, actions, rowAction }) {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: size }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // console.log("------");
  // console.log("page", page);
  // console.log("headers", headers);
  // console.log("------");

  return (
    <>
      <Row className={allowAdd ? "d-flex justify-content-between" : "flex-end-center"}>
        {allowAdd && <Col Col xs="auto" sm={6} lg={4}>
          <Button onClick={() => console.log("Clicked...")} className="m-2 fs--2 rounded-pill" size="sm" variant="outline-primary">
            <FontAwesomeIcon icon="plus" />
            {" Legg til"}
          </Button>
        </Col>}
        <Col xs="auto" sm={6} lg={4}>
          <TableSearchBox
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <Table
        responsive
        bordered
        striped
        hover
        {...getTableProps()}
        className="fs--1 mb-0 mt-3"
      >
        <thead className="bg-200 text-900">
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )}
              </th>
            ))}
            {actions &&
              <th className="text-end" scope="col">Actions</th>
            }
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()} className={rowAction ? "cursor-pointer" : ""}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()} onClick={rowAction ? () => { rowAction(row.original); } : null}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
                {actions &&
                  <td className="text-end">
                    <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" />
                    <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" />
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </Table>
      {page.length === 0 && (
        <div className="text-center">
          <p className="fw-bold fs-1 mt-3">No data found</p>
        </div>
      )}
      <TableFooter
        page={page}
        pageSize={pageSize}
        pageIndex={pageIndex}
        rowCount={data.length}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />

    </>
  );
}

TableSearchable.defaultProps = {
  allowAdd: false,
  actions: false
};

export default TableSearchable;