import { Card, CardBody, Row, Col, Input, Button, Label, FormGroup, Alert } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { fetchCustomers, fetchUser } from '../../../data/dataFetch';
import React, { useState, useEffect } from 'react';
import { updateUser, createUser, deleteUserById } from '../../../data/dataUpdate';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { isIterableArray } from "../../../helpers/utils";
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';

const User = () => {

    const { id } = useParams();
    const [isNew, setIsNew] = useState(true);
    const [isEditing, setIsEditing] = useState(true);
    const [initialUser, setInitialUser] = useState({ customer_id: 0, email: '', name: '', password: '' });
    const [user, setUser] = useState(initialUser);
    const [customers, setCustomers] = useState(null);
    const history = useHistory();



    useEffect(() => {
        const loadUser = async () => {
            var data = await fetchUser(id);
            var copy = JSON.parse(JSON.stringify(data));
            setIsNew(false);
            setInitialUser(copy);
            setUser(copy);
        }
        if (id === 'new') {
            setInitialUser({ customer_id: 0, email: '', name: '', password: '' });
            setUser(initialUser);
            setIsNew(true);
        }
        else
            loadUser();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomers(await fetchCustomers());
        }
        loadCustomers();
    }, [])


    const isUserUnchanged = () => {
        return (JSON.stringify(initialUser) === JSON.stringify(user));

    }


    const insertOrUpdateUser = async () => {
        if (isNew) {
            const newUser = await createUser(user);
            if (newUser){
                setIsEditing(false);
                setInitialUser(user);
                toast.success('bruker er lagt til'); 
            }
            else
                toast.error('Det har oppstått en feil ');
        } else {
            const res = await updateUser(user);
            if (res.status === 200) {
                setIsEditing(false);
                setInitialUser(user);
            }
            else
                toast.error('Det har oppstått en feil ');
        }


    }

    const deleteCurrentUser = async () => {
        let result = await confirm({
            title: "Bekrefte sletting",
            message: "Bekrefte sletting.",
            confirmText: "Bekrefte",
            cancelText: "Avbryt",
        });
        if (result) {
            var res = await deleteUserById(user);
            if (res)
                history.push(`/`);
        }

    }


    return (
        <div className="mb-5">


            <Card>
                <Row className="align-items-center p-3" >
                    <Col>
                        <h5>{isNew ? "NY" : ""} BRUKER {!isNew ? user.name : ""}</h5>
                    </Col>
                    <Col xs="auto" className="text-right">
                        <Link to={`/`} className="mr-2">
                            <Button color="primary" size="sm"> &lt; Bruker</Button>
                        </Link>
                        {!isNew &&
                            < Button
                                onClick={() => { deleteCurrentUser(); }}
                                color="danger" size="sm">Slett
                            </Button>}
                        {" "}
                        < Button
                            onClick={() => {
                                if (isEditing) {
                                    setUser(JSON.parse(JSON.stringify(initialUser)));
                                    if (isNew) {
                                        history.push(`/`);
                                    }
                                }
                                setIsEditing(!isEditing);
                            }}
                            color="warning" size="sm"> Avbryt
                        </Button>
                    </Col>
                </Row>
                <CardBody>


                    <FormGroup className="mr-2 mb-2">
                        <Label>Brukernavn</Label>
                        <Input
                            value={user.name}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setUser({ ...user, name: target.value })
                            }}
                        />
                    </FormGroup>

                    <FormGroup className="mr-2 mb-2">
                        <Label>Epost</Label>
                        <Input
                            value={user.email}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setUser({ ...user, email: target.value })
                            }}
                            readOnly={!isNew ? 'readOnly' : undefined}
                        />
                    </FormGroup>


                    <FormGroup className="mr-2 mb-2">
                        <Label>Tilgangsnivå</Label>
                        <Select
                            options={
                                isIterableArray(customers) && Object.values(customers).map((customer) => ({ value: customer.customer_id, label: customer.customer_name }))
                            }
                            value={({ value: user.customer_id, label: user.customer_name })}
                            onChange={({ value }) => {
                                var selectedCustomer = customers.find(v => v.customer_id === Number(value));
                                setUser({ ...user, customer_id: value, customer_name: selectedCustomer.customer_name });

                            }}
                        />
                    </FormGroup>


                    {isNew && <FormGroup className="mr-2 mb-2">
                        <Label>Nytt passord</Label>
                        <Input
                            type="password"
                            value={user.password}
                            onFocus={(event) => { event.target.select() }}
                            onChange={({ target }) => {
                                setUser({ ...user, password: target.value })
                            }}
                        />
                    </FormGroup>}

                </CardBody>
            </Card>

            {
                isUserUnchanged() ? null : <div className="fixed-top">
                    <Alert color="danger">
                        <Row>
                            <Col sm="auto">
                                <Button onClick={() => insertOrUpdateUser()} color="primary" >
                                    Bruk
                                </Button>
                                {" "}
                                {!isNew &&
                                    <Button color="danger" >
                                        Tilbakstil
                                    </Button>
                                }
                            </Col>
                            <Col className="mt-2" sm="auto">
                                <span >
                                    Vil du lagre endringene?
                                </span>
                            </Col>
                        </Row>
                    </Alert>
                </div>
            }

        </div >

    );

};

export default User;
