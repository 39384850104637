import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from '../components/common/AuthenticatedRoute';
import About from '../components/home/About';
import PrivacyPolicy from '../components/home/PrivacyPolicy';
import ChangePassword from '../components/auth/ChangePassword';
import ForgotPassword from '../components/auth/ForgotPassword';
import Home from '../components/home/Home';
// import Login from '../components/auth/Login';
import NotFound from '../components/common/NotFound';
import PostBox from '../components/home/postboxes/PostBox';
//import PostBoxes from '../components/home/postboxes/PostBoxes';
import NewCardAccess from '../components/home/postboxes/NewCardAccess';
import Building from '../components/home/buildings/Building';
import Resident from '../components/home/residents/Resident';
import User from '../components/home/users/User';
import Customer from '../components/home/customers/Customer';

import loadable from '@loadable/component';
import UnauthenticatedRoute from '../components/common/UnauthenticatedRoute';
const Login = loadable(() => import('../components/auth/Login'));

const DashboardRoutes = () => {
    useEffect(() => {
        Login.preload();
    }, []);

    return (
        <Switch>
            <AuthenticatedRoute path="/" exact component={Home} />
            <UnauthenticatedRoute path="/login" exact component={Login} />
            <Route path="/about" exact component={About} />
            <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
            <AuthenticatedRoute path="/changePassword" exact component={ChangePassword} />
            <UnauthenticatedRoute path="/ForgotPassword" exact component={ForgotPassword} />
            <AuthenticatedRoute path="/postbox/:id" exact component={PostBox} />
            <AuthenticatedRoute path="/postbox/:id/add-access" exact component={NewCardAccess} />
            <AuthenticatedRoute path="/building/:id" exact component={Building} />
            {/* <AuthenticatedRoute path="/home/postboxes" exact component={PostBoxes} /> */}
            <AuthenticatedRoute path="/resident/:id" exact component={Resident} />
            <AuthenticatedRoute path="/user/:id" exact component={User} />
            <AuthenticatedRoute path="/customer/:id" exact component={Customer} />
            {/*Redirect*/}
            <Route path={`/errors/404`} component={NotFound} />
            <Redirect to="/errors/404" />
        </Switch>
    );
};

export default DashboardRoutes;
