import React, { useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

function AddressMap({ addresses }) {

  // const [map, setMap] = useState(null);
  let defaultLat = [59.9139,  10.7522];
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

  useEffect(() => {
    const addressToLatLng = async (address) => {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
      );
      const data = await response.json();
      if (data.length > 0)
        return [data[0].lat, data[0].lon];
      else
        return defaultLat;          
    };

    
    L.Marker.prototype.options.icon = DefaultIcon;

    const loadMap = async () => {
      const mapInstance = L.map("map").setView(defaultLat, 10);
      // setMap(mapInstance);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "Map data &copy; OpenStreetMap contributors",
        maxZoom: 18,
      }).addTo(mapInstance);

      addresses.forEach(async (address) => {
        const position = await addressToLatLng(address.address);
        L.marker(position)
          .addTo(mapInstance)
          .bindPopup(address.name);
          //.openPopup();
      });
    };

    loadMap();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  return <div id="map" style={{ height: "100vh" }}></div>;
}

export default AddressMap;